import { chains } from '@web3modal/ethereum'

// const customChain = {
//   id: 404,
//   name: 'tHepton',
//   network: 'tHepton',
//   nativeCurrency: {
//     decimals: 18,
//     name: 'tHepton',
//     symbol: 'HTE'
//   },
//   rpcUrls: {
//     default: 'https://testnet.hepton.io'
//   },
//   blockExplorers: {
//     default: { name: 'Hepton Scan', url: 'https://testnet.heptonscan.com' }
//   },
//   testnet: true
// }


export const  config = {
  projectId: 'd3873ed18b4ac26cf6f5dffdb2fe9cbd',
  theme: 'dark',
  accentColor: 'teal',
  ethereum: {
    autoConnect: true,
    appName: 'Fiery Pace',
    chains: [chains.binanceSmartChain],
  //  providers: [providers.jsonRpcProvider()],
  }
}