import React from "react";
import styles from "./SampleDrops.module.css";
import { alien0944 } from "../../images";
import { nft1, nft2, nft3, nft4 } from "../../images";

const SampleDrops = () => {
  const data = [
    { img: nft1, name: "Fiery Pace", id: "" },
    { img: nft2, name: "Fiery Pace", id: "" },

    { img: nft3, name: "Fiery Pace", id: "" },

    { img: nft4, name: "Fiery Pace", id: "" },
  ];
  return (
    <section className={styles.sampledrops}>
      <h4 className={styles.title}>Fiery Pace Collection</h4>
      <div className={styles.wrapper}>
        {data.map((item, index) => (
          <div className={styles.imageContainer} key={index}>
            <img src={item.img} alt="#" className={styles.image} />

            <p className={styles.name}>
              {item.name} {item.id}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default SampleDrops;
