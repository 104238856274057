import React from "react";
import styles from "./HeroSection.module.css";
import { aliens } from "../../images";

const HeroSection = () => {
  return (
    <section className={styles.heroSection}>
     
      <h2 className={styles.heading}>Fiery Pace</h2>
      <div className={styles.whiteList}>
        <span className={styles.circle} />
        <span>Mint is live
</span>
      </div>
      <p className={styles.text}>
       
FIERY PACE IS A 10,000 NFT COLLECTION
OF SUPER SOLDIERS THAT ARE ON A MISSION TO
USHER IN A NEW COMPLEX WORLD ORDER.

      </p>
    </section>
  );
};

export default HeroSection;
