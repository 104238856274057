import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Web3Modal } from '@web3modal/react';
import {useState} from "react"
import { config } from "./components/Web3Modal/Config";
import Header from "./components/Header/Header";
import Home from "./pages/Home";
import { useEffect } from "react";
import {useGetProvider} from "./components/Contract/Provider";


function App() {

const [totalMinted, settotalMinted] = useState(0);

const Getprovider = async () => {
    const myprovider =  useGetProvider();
    console.log(myprovider)
}
  
useEffect(() => {
 // Getprovider();
}, [totalMinted]);

  return (
    <>
      <BrowserRouter>
      <Web3Modal config={config}/>
        <Header />
        <Routes>
        <Route path="/" element={<Home />}></Route>
          <Route path="/:id" element={<Home />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
