export const Addresses = {
    usdt : "0x55d398326f99059fF775485246999027B3197955",
    busd : "",
    usdc : "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    mintAddress : "0x868507AA942EdE213Cf682a475AfdF74133a121d"
}
export const chainID = {
    id : 56
}
export const rpcURL = {
    goerli : "https://goerli.infura.io/v3/c2b7fbdf6fde4bb58e8983e7822a352a",
    thepton : "https://testnet.hepton.io"
}