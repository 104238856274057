import React, { useEffect, useState, useRef } from "react";
import { BiCheck, BiPlus, BiMinus } from "react-icons/bi";
import {ethers} from "ethers";
import { abi } from "../Contract/Abi"
import { erc20 } from "../Contract/Abi"
import { useProvider, useAccount, useConnectModal ,useSigner} from '@web3modal/react';
import * as alert from "../Alert/Alert";
import truncateEthAddress from 'truncate-eth-address'
import LoadingSpinner from "./Loader/Loader";
import ProgressBar from "./Progressbar/Progressbar";
import styles from "./GetYourNft.module.css";
import MyCountDown from "./CountDown/CountDown";
import { useParams } from "react-router-dom";
import {Addresses, chainID} from  "../Contract/Constants";
import {rpcURL} from"../Contract/Constants";
const GetYourNft = () => {
  const [buyToken, setbuyToken] = useState('USDT');
  const [isUSDT, setisUSDT] = useState(false);
  const [isUSDC, setisUSDC] = useState(false);
  const [wallet, setwallet] = useState('');
  const [currency, setCurrency] = useState(0);
  const [totalminted, settotalminted] = useState(0);
  const [userMinted, setuserMinted] = useState(0);
  const [refEarning, setRefEarning] = useState(0);
  const {id} = useParams();
  const [Ref , setRef] = useState("0x0000");

  const isReload = useRef(false);

  const { provider, isReady } = useProvider(chainID.id);
  const [IsdappReady , setisDappReady] = useState(false);
  const [IsdappReady2 , setisDappReady2] = useState(false);
  const { data, error, isLoading, refetch } = useSigner(chainID.id)
  const { isOpen, open, close } = useConnectModal()
  const signer = data;

  const { account } =  useAccount();

  const etherProvider = new ethers.providers.JsonRpcProvider("https://bsc-dataseed1.binance.org");
  const mintContract = new ethers.Contract(Addresses.mintAddress, abi, etherProvider);

  

  

  /** */
  const allowance = async () => {
    try{
    const erc20Contract = new ethers.Contract(Addresses.usdt, erc20, etherProvider);
    const allo = await erc20Contract.allowance(wallet, Addresses.mintAddress);
   // console.log(allo , "alloc")
    return allo;
  } catch (e) {
    
  }
  }
  const allowanceUSDC = async () => {
    try {
    const erc20Contract = new ethers.Contract(Addresses.usdc, erc20, etherProvider);
    const allo = await erc20Contract.allowance(wallet, Addresses.mintAddress);
   // console.log("Addresses.BUSD", allo)
    return allo;
    } catch(e){
     console.log(e)
    }
  }

  function pushRef() {
    const data = {
      user : account.address,
      referrer : Ref,
      network : "BSC",
    }
    fetch('https://next-stack-default-rtdb.firebaseio.com/RefswithNetwork.json' , {
      method : "POST",
      body : JSON.stringify(data),
      header : {
        'content-Type' : 'application/json'
      }
    })
    console.log("Success")
  }

  const ApproveUSDT = async () => {
    try {
    const erc20Signer = new ethers.Contract(Addresses.usdt, erc20, data);
    const tx = await erc20Signer.approve(Addresses.mintAddress, "79299293382937297363737272772277272722378283782938289332");
    const txIndex = await tx.wait();
    if(txIndex.blockNumber){
      pushRef();
      alert.AlertTxnConfirmed();
      ApprovalChecker();
    }
  }  catch (e) {
    console.log(e)
    if (e.code  === 4001) {
      alert.AlertRejectedTXN();
    } else if (e) {
      alert.AlertFailedTXN()
    }
  }
}
  const approveUSDC = async () => {
    try{
    const erc20Signer = new ethers.Contract(Addresses.usdc, erc20, data);
    const tx = await erc20Signer.approve(Addresses.mintAddress, "79299293382937297363737272772277272722378283782938289332");
    const txIndex = await tx.wait();
    if(txIndex.blockNumber){
      pushRef();
      alert.AlertTxnConfirmed();
      ApprovalChecker();
    }
  } catch (e) {
    if (e.code  === 4001) {
      alert.AlertRejectedTXN();
    } else if (e) {
      alert.AlertFailedTXN()
    }
  }
}

const mintFP = async () => {
  try {
  const nftContract = new ethers.Contract("0x868507AA942EdE213Cf682a475AfdF74133a121d", abi, data);
  const tx = await nftContract.mint(currency, mint, Ref);
  const txIndex = await tx.wait();
  if(txIndex.blockNumber){
    pushRef();
    alert.AlertTxnConfirmed();
    ApprovalChecker();
  }
}  catch (e) {
  console.log(e)
  // if (e.code  === 4001) {
  //   alert.AlertRejectedTXN();
  // } else if (e) {
  //   alert.AlertFailedTXN()
  // }
}
}

const connect = async () => {
   open()
   setisDappReady(true);
   ;
}

const titleAndFunc = () => {
  if (!account.address) {
    return {
      title : "Connect Wallet",
      func : connect
    }  
  }else if (buyToken == 'USDT' & isUSDT == false){
    return {
      title : "Approve",
      func : ApproveUSDT
    }
  } else if (buyToken == 'BUSD' & isUSDC == false ) {
    return {
      title : "Approve",
      func : approveUSDC
    }
}
  else {
    return {
      title : "Mint",
      func : mintFP
    }
  }
}

const  buttonStatus = titleAndFunc();
const buttonFunc = buttonStatus.func;

/** */
const GetTotalMinted = async () => {
    const totalMinted = await mintContract.getAllMinted();
    settotalminted(parseInt(totalMinted));
}
/** */

const getRef = async (addr) => {
  try {
  const userRef = await mintContract.totalRefEarning(ethers.utils.getAddress(addr));
  setRefEarning((ethers.utils.formatEther(userRef)));
  }
  catch (e){
  
  }
} 

const getUserMinted = async () => {
  try {
  const userMinted = await mintContract.getUserMinted(ethers.utils.getAddress(wallet));
  const UM = parseInt(userMinted);
  setuserMinted(UM);
  return UM;
  }
  catch (e){
    return 0;
  //console.log(e);
  }
} 
/** */


/** */

const ApprovalChecker = async () => {
  try {
  if (buyToken == 'USDT') {
   
    const approve = await allowance();
        if (parseInt(approve) < "79299293382937297363737272772277272722378283782938") {
          setisUSDT(false)
         
        } else if (parseInt(approve) > "79299293382937297363737272772277272722378283782938") {
         // console.log(approve ,"this is Addresses.usdt runninf")
          setisUSDT(true);
        }
  } else if (buyToken == 'BUSD') {
    const approve = await allowanceUSDC();
        if (parseInt(approve) < "79299293382937297363737272772277272722378283782938") {
          setisUSDC(false);
        } else if (parseInt(approve) > "79299293382937297363737272772277272722378283782938") {
          setisUSDC(true);
        }
  }
} catch (e) {
 // console.log(e);
}

}


const setReferrer = async () => {
  if (ethers.utils.isAddress(id) == true) {
    setRef(id);
  } else if (!id) {
    setRef("0x1e580CA654e1ca2863B5E7FD6805229b07950676");
  } else if (ethers.utils.isAddress(id) == false) {
  setRef("0x1e580CA654e1ca2863B5E7FD6805229b07950676");
  }
  if (userMinted == 5){
    setMint(0)
  }
  const userMintedValue = await getUserMinted(wallet);
  setuserMinted(parseInt(userMintedValue));
}

// const connectwalletonStartUp = props => {
// if (localStorage.getItem("wagmi.connected") === true){
//   return;
// } else {
//   open();
// }
// }

const init = () => {
  // GetTotalMinted();
  getRef(wallet);
  // getUserMinted();
 ApprovalChecker();
 titleAndFunc();
 setwallet(account.address);
 setReferrer();
//  connectwalletonStartUp();
}

useEffect(() => {
init();
}, [init, userMinted, currency, ]);

useEffect(() => {
  const interval = setInterval(() => {
    refetch(chainID.id);
}, 2000);
  return () => clearInterval(interval);
}, []);

  const [mint, setMint] = useState(1);
  const [minting, setMinting] = useState(false);

  const increase = () => {
    if (mint + userMinted < 5) {
      setMint((prev) => prev + 1);
    }
    if (mint + userMinted == 5) {
      alert.AlertMaxBuy()
    }
  };
  const decrease = () => {
    if (mint > 1) {
      setMint((prev) => prev - 1);
    }
    if (mint == 1) {
      alert.AlertMinBuy();
    }
  };
  const currencies = ["usdt", "busd"];
  return (
    <section className={styles.getYourNft}>
      <div className={styles.headingAndLive}>
        <h3 className={styles.heading}>Mint is Live !</h3>
        <div className={styles.timerContainer}>
          <div className={styles.timer}>
            <span className={styles.circle} />
            <span className={styles.live}>Live</span>
            <span className={`${styles.live} ${styles.time}`}>
              {" "}
              {/* <MyCountDown dayCount="Dec 11 2022 05:30:01" />{" "} */}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.mintingWrapper}>
        <p className={styles.mintingDate}>
          <span className={styles.key}> </span>
          <span className={styles.value}>
            {/* Dec 11 2:00am CET */} 
          </span>
        </p>{" "}
        <p className={styles.mintingDate}>
          <span className={styles.key}> Price : </span>
          <span className={styles.value}>
            10 {buyToken} <span className={styles.key}></span> 
          </span>
        </p>
        <p className={styles.mintingDate}>
          <span className={styles.key}> Referrer : </span>
          <span className={styles.value}>
            {truncateEthAddress(Ref)} <span className={styles.key}></span> 
          </span>
        </p>
        <p className={styles.mintingDate}>
          <span className={styles.key}> My Referral Earnings : </span>
          <span className={styles.value}>
            {refEarning} USD <span className={styles.key}></span> 
          </span>
        </p>
      </div>
      {/* <h5 className={styles.title}>
        {buyToken} Amount{" "}
        <span className={styles.maxMint}>{buyToken} Max:  {5 - userMinted}</span>
      </h5> */}
      <h5 className={styles.title}>
        How many NFTs to mint?{" "}
        <span className={styles.maxMint}>Max mint: {5 - userMinted}</span>
      </h5>
      <div className={styles.mintContainer}>
        <BiMinus
          onClick={decrease}
          style={{userSelect : 'none'}}
          className={`${styles.mint} ${mint === 0 && styles.mintDeactive}`}
        />
        <span className={styles.mint}  style={{userSelect : 'none'}}>{mint}</span>{" "}
        <BiPlus
        style={{userSelect : 'none'}}
          onClick={increase}
          className={`${styles.mint} ${mint === 5 && styles.mintDeactive}`}
        />
      </div>
      <h5 className={styles.title}>Select Purchase Currency? </h5>
      <div className={styles.currencyContainer}>
        {currencies.map((item, i) => (
          <div
            className={`${styles.currency} ${
              currency === i && styles.currencyActive
            }`}
            key={i}
            onClick={async () => {
              if(i == 0) {
                  setbuyToken("USDT");
              } else if (i ==1) {
                setbuyToken("BUSD");
              }
            // getUserMinted();
             ApprovalChecker();
             titleAndFunc();
              setMinting(true);
              setTimeout(() => {
                setMinting(false);
              }, 1500);
              setwallet(account.address);
              setCurrency(i)}
              
            }
          >
            {currency === i && <BiCheck className={styles.ok} />} {item}
          </div>
        ))}
      </div>
      <button
        className={styles.loader}
        onClick={() => {
          buttonFunc();
          setMint(1);
          setMinting(true);
          setTimeout(() => {
            setMinting(false);
          }, 5000);
        }}
      >
        {minting ? (
          <LoadingSpinner />
        ) : (
          <span className={styles.minting}>{buttonStatus.title}</span>
        )}
      </button>
      <div className={styles.transaction}>
        <p className={styles.transactionKey}>Transaction Fee</p>
        <p className={`${styles.transactionValue} ${styles.transactionKey}`}>
          ~0.09$ BNB
        </p>
      </div>
      <div className={`${styles.transaction} ${styles.transactionBottom}`}>
        <p className={styles.transactionKey}>Total Fee</p>
        <p className={`${styles.transactionValue} ${styles.transactionKey}`}>
          {mint * 10} {buyToken}
        </p>
      </div>{" "}
      <div className={styles.progressAndMinted}>
        <ProgressBar bgcolor="#404042" progress={userMinted} max={5} height={10} />
        <div className={styles.mintedContainer}>
          <p className={styles.minted}>My Mint Amount</p>
          <p className={styles.minted}>{userMinted} / 5</p>
        </div>
      </div>{" "}
      {/* <div className={styles.progressAndMinted}>
        <ProgressBar bgcolor="#404042" progress={totalminted} max={10000} height={10} />
        <div className={styles.mintedContainer}>
          <p className={styles.minted}>Total Minted</p>
          <p className={styles.minted}>{totalminted} / 10000</p>
        </div>
      </div> */}
    </section>
  );
};

export default GetYourNft;
