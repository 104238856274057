import React from "react";
import GetYourNft from ".././components/GetYourNft/GetYourNft";

import HeroSection from ".././components/HeroSection/HeroSection";
import SampleDrops from ".././components/SampleDrops/SampleDrops";
import styles from "./Home.module.css";

function Home() {
  return (
    <div className={styles.home}>
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <HeroSection />
          <SampleDrops />
        </div>
        <div className={styles.right}>
          <GetYourNft />
        </div>
      </div>
    </div>
  );
}

export default Home;
